/***********************************
 *                                 *
 *         KEYBODO SECTION         *
 *                                 *
 ***********************************/
export const LEFT = 37;
export const UP = 38;
export const RIGHT = 39;
export const DOWN = 40;
export const SPACE = 32;

export const keybodo = {
  keysDown: {},
  isDown (keyCode) {
    var keyCodes = keyCode instanceof Array ? keyCode : [keyCode];

    for (const key of keyCodes) {
      const code = typeof(key) === 'string' || key instanceof String ? key.toUpperCase().charCodeAt(0) : key;
      if (this.keysDown[code]) { return true; }
    }
  },

  onButtonDown (e) {
    if (!e.ctrlKey && !e.metaKey && ((e.which >= 37 && e.which <= 40) || (e.which >= 65 && e.which <= 90) || e.which == 32)) {
      e.preventDefault();
      keybodo.keysDown[e.which] = true;
    }
  },

  onButtonUp (e) {
    delete keybodo.keysDown[e.which];
  },

  onMouseDown (e) {
    let key = e.target.dataset["keycode"];
    if ((key >= 37 && key <= 40) || (key >= 65 && key <= 90) || key == 32) {
      e.preventDefault();
      keybodo.keysDown[key] = true;
    }
  },

  onMouseUp (e) {
    let key = e.target.dataset["keycode"];
    delete keybodo.keysDown[key];
  },

  clearAll () {
    this.keysDown = {};
  }
};
