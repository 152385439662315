/***********************************
 *                                 *
 *           UTIL SECTION          *
 *                                 *
 ***********************************/

export function logError(err) {
  console.log(err);
  console.log(err.stack);
}

export function getSecureHexString(gameBlob) {
  var s = localStorage.getItem(gameBlob.state.guid);

  if(!s) {
    var buf = window.crypto.getRandomValues(new Uint8Array(16));
    s = '';
    for (var b of buf) {
      var hex = b.toString(16);
      s += (hex.length == 1 ? '0' + hex : hex);
    }
    localStorage.setItem(gameBlob.state.guid, s);
  }
  return s;
}

export function logPeerInfo(peerConn) {
  if (!!window.chrome) {
    peerConn.getStats().then((stats) => {
      console.info(stats);
      let values = [...stats.values()];
      console.info(values);
      let transport = values.find(s => s.type == 'transport');
      let pair = values.find(s => s.type == 'candidate-pair' && s.id == transport.selectedCandidatePairId);
      let localCandidate = values.find(s => s.type == 'local-candidate' && s.id == pair.localCandidateId);
      let remoteCandidate = values.find(s => s.type == 'remote-candidate' && s.id == pair.remoteCandidateId);

      console.group("webrtc info");
      console.info("transport %s, %dkB/%dkB tx/rx", transport.dtlsState, transport.bytesSent / 1024, transport.bytesReceived / 1024);
      console.info("local candidate: %s:%d/%s (%s)", localCandidate.ip, localCandidate.port, localCandidate.protocol, localCandidate.candidateType);
      console.info("remote candidate: %s:%d/%s (%s)", remoteCandidate.ip, remoteCandidate.port, remoteCandidate.protocol, remoteCandidate.candidateType);
      console.groupEnd("webrtc info");
    });
  }
}
